// SwiperFeature
//
// 常に1カラムでページに大きく使われているカルーセル

import Swiper from 'swiper/bundle';

class SwiperFeature {
  constructor({ elm }) {
    this.swiperElm = elm;
    this.swiper = new Swiper(elm, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: "[data-role='feature-swiper-pagination']",
        clickable: true,
      },
    });
  }
}

export default SwiperFeature;
