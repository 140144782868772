class EmergencyArticleAlert {
  constructor({ elm, storageName }) {
    this.elm = elm;
    this.closerAreaElm = this.elm.querySelector('[data-role="emergency-article-alert-closer-area"]');
    this.closerElm = this.elm.querySelector('[data-role="emergency-article-alert-closer"]');

    this.storageName = storageName ?? 'hasClosedEmergencyAlert';

    // パーツのHTMLが不完全な場合は処理停止
    if (!this.closerElm || !this.closerAreaElm) {
      throw new Error('[Error : EmergencyArticleAlert] 必要なDOM要素が存在しません');
    }

    // アラートを閉じた経験が過去にない場合、アラートを表示
    if (!this.hasClosedAlert()) this.showUi();

    // [特例] sessionStorageが使えない端末ではアラート自体を閉じさせない
    if (window.sessionStorage === null) {
      this.showFixedUi();
      return;
    }

    this.closerElm.addEventListener('click', () => {
      this.hideUi();
      this.activeStorage();
    });
  }

  hasClosedAlert() {
    return sessionStorage.getItem(this.storageName) !== null;
  }

  hideUi() {
    this.elm.style.display = 'none';
  }

  showUi() {
    this.elm.style.display = 'block';
  }

  showFixedUi() {
    this.closerAreaElm.remove();
  }

  activeStorage() {
    sessionStorage.setItem(this.storageName, 'true');
  }
}

export default EmergencyArticleAlert;
