// SwiperJumbo
//
// 常に1カラムでページに大きく使われているカルーセル

import Swiper from 'swiper/bundle';

class SwiperJumbo {
  constructor({ elm }) {
    this.swiper = new Swiper(elm, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      pagination: {
        el: "[data-role='jumbo-swiper-pagination']",
        clickable: true,
      },
    });
  }
}

export default SwiperJumbo;
