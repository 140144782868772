// SwiperCard
//
// PCサイズで3カラムとなる汎用カルーセル

import Swiper from 'swiper/bundle';

class SwiperCards {
  constructor({ elm }) {
    this.swiperElm = elm;
    this.nextNav = elm.querySelector("[data-role='cards-swiper-next']");
    this.prevNav = elm.querySelector("[data-role='cards-swiper-prev']");
    this.navLocationReferenceElm = elm.querySelector('[data-role="cards-swiper-nav-location-reference-elm"]');

    this.timerId = null;

    this.swiper = new Swiper(elm, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
      navigation: {
        nextEl: this.nextNav,
        prevEl: this.prevNav,
      },
    });

    window.addEventListener('load', () => {
      if (this.navLocationReferenceElm) this.updateNavYLocation();
    });

    window.addEventListener('resize', () => {
      if (this.navLocationReferenceElm) {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          this.updateNavYLocation();
        }, 100);
      }
    });
  }

  updateNavYLocation() {
    const top = `${Math.floor(this.navLocationReferenceElm.getBoundingClientRect().height / 2)}px`;
    this.nextNav.style.top = top;
    this.prevNav.style.top = top;
  }
}

export default SwiperCards;
