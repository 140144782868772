// SwiperSinglePageRecommend
//

import Swiper from 'swiper/bundle';

class SwiperSinglePageRecommend {
  constructor({ elm }) {
    this.swiper = new Swiper(elm, {
      slidesPerView: 1.4,
      spaceBetween: 16,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      breakpoints: {
        768: {
          slidesPerView: 2.2,
          spaceBetween: 32,
        },
      },
      pagination: {
        el: elm.querySelector("[data-role='single-page-recommend-swiper-pagination']"),
        clickable: true,
      },
      navigation: {
        nextEl: elm.querySelector("[data-role='single-page-recommend-swiper-next']"),
        prevEl: elm.querySelector("[data-role='single-page-recommend-swiper-prev']"),
      },
    });
  }
}

export default SwiperSinglePageRecommend;
