import SwiperCmn from './components/SwiperCmn';
import SwiperCards from './components/SwiperCards';
import SwiperFeature from './components/SwiperFeature';
import SwiperJumbo from './components/SwiperJumbo';
import SwiperSingle from './components/SwiperSingle';
import SwiperSinglePageRecommend from './components/SwiperSinglePageRecommend';
import ArchiveFilter from './components/ArchiveFilter/ArchiveFilter';
import $ from 'jquery';
import EmergencyArticleAlert from './components/EmergencyArticleAlert';

import './components/SwiperSinglePageMv';

// header関連
if (document.querySelector('.global-header') !== null) {
  // menu開閉ボタン
  const globalHeader = document.querySelector('.global-header');
  document.querySelector('.js-menu-toggle').addEventListener('click', function () {
    this.classList.toggle('is-menu-open');
  });

  // グロナビ開閉
  const globalMenuOpeners = document.querySelectorAll('.js-global-main-menu-toggle');
  for (let i = 0; i < globalMenuOpeners.length; i++) {
    globalMenuOpeners[i].addEventListener('click', function (e) {
      this.classList.toggle('is-main-menu-open');
    });
  }

  // 検索ボックス展開
  const searchboxOpener = document.querySelector('.js-searchbox-toggle');
  searchboxOpener.addEventListener('click', function () {
    this.classList.toggle('is-searchbox-open');
  });
}

// ArchiveFilter
//
// アーカイブ一覧ページにおける絞り込みフォーム要素パーツ
if (document.querySelector('[data-role="archive-filter"]')) {
  if (document.querySelector('.not-use-areamap') !== null) {
    // eslint-disable-next-line no-new
    new ArchiveFilter({ disableAreaMapMode: true });
  } else {
    // eslint-disable-next-line no-new
    new ArchiveFilter();
  }
}

// Carousel
//
// 汎用 Swiper要素

const cardsSwiperElms = document.querySelectorAll("[data-role='cards-swiper']");
if (cardsSwiperElms.length) {
  cardsSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperCards({ elm });
  });
}

const cmnSwiperElms = document.querySelectorAll("[data-role='cmn-swiper']");
if (cmnSwiperElms.length) {
  cmnSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperCmn({ elm });
  });
}

const featureSwiperElms = document.querySelectorAll("[data-role='feature-swiper']");
if (featureSwiperElms.length) {
  featureSwiperElms.forEach((elm) => {
    new SwiperFeature({ elm });
  });
}

const jumboSwiperElms = document.querySelectorAll("[data-role='jumbo-swiper']");
if (jumboSwiperElms.length) {
  jumboSwiperElms.forEach((elm) => {
    new SwiperJumbo({ elm });
  });
}

const singleSwiperElms = document.querySelectorAll("[data-role='single-swiper']");
if (singleSwiperElms.length) {
  singleSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperSingle({ elm });
  });
}

const spotRecommendSwiperElm = document.querySelectorAll("[data-role='single-page-recommend-swiper']");
if (spotRecommendSwiperElm.length) {
  spotRecommendSwiperElm.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperSinglePageRecommend({ elm });
  });
}

// tab
//
// 汎用 jquery利用

if (document.querySelector('#tab-cmn') !== null) {
  $(() => {
    $('.tab-cmn-menu__item').on('click', function () {
      let tabWrap = $(this).parents('#tab-cmn');
      let tabBtn = tabWrap.find('.tab-cmn-menu__item');
      let tabContents = tabWrap.find('.tab-cmn-contents');
      tabBtn.removeClass('is-active');
      $(this).addClass('is-active');
      let elmIndex = tabBtn.index(this);
      tabContents.removeClass('is-active');
      tabContents.eq(elmIndex).addClass('is-active');
    });
  });
}

// 緊急ニュース用アラート
//

const emergencyArticleAlertElm = document.querySelector('[data-role="emergency-article-alert"]');
if (emergencyArticleAlertElm) {
  new EmergencyArticleAlert({
    elm: emergencyArticleAlertElm,
    storageName: 'hasClosedGunmaEmergencyAlert',
  });
}
