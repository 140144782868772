// SwiperSinglePageMv
//

import Swiper from 'swiper/bundle';

// 詳細ページの画像スライダ
let singlePageItemSwiper = document.querySelector('[data-role="single-page-mv-swiper"]');
if (singlePageItemSwiper !== null) {
  // slideの個数を取得
  let swiperItems = singlePageItemSwiper.querySelectorAll('.swiper-slide').length;

  // swiperの指定
  let singlePageMvSwiper;
  let singlePageMvThumbSwiper;

  // swiper設定
  const createThumbSwiper = function (loopThumb) {
    let thumbParam = {
      slidesPerView: 2.4,
      spaceBetween: 16,
      loop: true,
      speed: 1500,
      allowTouchMove: true,
      breakpoints: {
        768: {
          slidesPerView: 5,
          spaceBetween: 32,
        },
      },
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      watchOverflow: true,
    };
    // loopしない場合は下記を書き換え
    if (!loopThumb) {
      thumbParam.loop = false;
      thumbParam.allowTouchMove = false;
    }
    singlePageMvThumbSwiper = new Swiper('[data-role="single-page-mv-thumb-swiper"]', thumbParam);
  };
  const createSwiper = function (loop) {
    let param = {
      slidesPerView: 1.2,
      spaceBetween: 16,
      centeredSlides: true,
      loop: true,
      allowTouchMove: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      navigation: {
        nextEl: '[data-role="single-page-mv-swiper-next"]',
        prevEl: '[data-role="single-page-mv-swiper-prev"]',
      },
      breakpoints: {
        768: {
          slidesPerView: 1.5,
          spaceBetween: 32,
        },
      },
      thumbs: {
        swiper: singlePageMvThumbSwiper,
      },
    };
    // loopしない場合は下記を書き換え
    if (!loop) {
      param.loop = false;
      param.allowTouchMove = false;
    }
    singlePageMvSwiper = new Swiper('[data-role="single-page-mv-swiper"]', param);
  };

  // 読み込まれた時
  function slideWidthWatch(load) {
    if (!load) {
      singlePageMvThumbSwiper.destroy(false, true);
      singlePageMvSwiper.destroy(false, true);
    }
    if (swiperItems === 2) {
      createThumbSwiper(false);
      createSwiper(true);
    } else if (swiperItems >= 3 && swiperItems <= 5) {
      if (768 < window.innerWidth) {
        createThumbSwiper(false);
        createSwiper(true);
      } else {
        createThumbSwiper(true);
        createSwiper(true);
      }
    } else {
      createThumbSwiper(true);
      createSwiper(true);
    }
  }

  // スライドの個数が5枚以下の時はリサイズイベント
  if (swiperItems <= 5) {
    window.addEventListener('resize', function () {
      slideWidthWatch(false);
    });
  }

  slideWidthWatch(true);
}
